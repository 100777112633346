import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LinkTo from '../../components/LinkTo';
import OfferRequest from '../../components/OfferRequest';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsTabletDokumenttipohjatImg from '../../components/images/QMCLoudsTabletDokumenttipohjatImg';
import QMCloudsDokumenttiMalliImg from '../../components/images/QMCloudsDokumenttiMalliImg';
import urls from '../../components/urls';
import kalenteri from '../../images/kalenteri.svg';

export default () => {
  const { t } = useTranslation();

  return (
    <BackgroundImage className="container-space">
      <Container>
        <Row className="mb-5">
          <Col>
            <h1 className="text-center index-hero-h1 text-white">
              {t('IndexHeroTitle')}
            </h1>
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col lg={3}>
            <div className="hero-img-height hidden-mobile">
              <QMCloudsTabletDokumenttipohjatImg className="img-w-250" />
            </div>
            <div className="hero-card">
              <p className="text-upper bold text-white">{t('IndexSoftware')}</p>
            </div>
          </Col>
          <Col lg={1}>
            <p className="hero-box-p text-orange">+</p>
          </Col>
          <Col lg={4}>
            <div className="hero-img-height hidden-mobile">
              <QMCloudsDokumenttiMalliImg className="img-w-150" />
            </div>

            <div className="hero-card">
              <p className="text-upper bold text-white">
                {t('IndexDocuments')}
              </p>
            </div>
          </Col>
          <Col lg={1}>
            <p className="hero-box-p text-orange">+</p>
          </Col>
          <Col lg={3}>
            <div className="hero-img-height hidden-mobile">
              <img
                src={kalenteri}
                alt="Kalenteri"
                className="img img-w-250 block m-auto"
              />
            </div>

            <div className="hero-card">
              <p className="text-upper bold text-white">{t('Index4Days')}</p>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <p className="text-center text-upper hero-box-p text-white">=</p>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={5}>
            <div className="hero-card-2">
              <p className="text-upper bold text-white">
                {t('IndexQualityManual')}
              </p>
            </div>
          </Col>
        </Row>

        <Row className="pt-5">
          <Col lg={6} className="mb-4">
            <h2 className="bold text-white">{t('IndexPrice')}</h2>
            <OfferRequest />
            <LinkTo
              url={urls.priceUrl}
              text={t('IndexLinktToPricing')}
              hoverWhite
            />
          </Col>
          <Col lg={6}>
            <h2 className="bold text-white">{t('IndexHeroP')}</h2>
            <LinkTo
              url={urls.qualitySystemUrl}
              text={t('IndexHeroToQualitySystem')}
              hoverWhite
            />
            <LinkTo
              url={urls.environmentalSystem}
              text={t('IndexHeroToEnvironmentalsystem')}
              hoverWhite
            />
            <LinkTo
              url={urls.occupationalSafetySystem}
              text={t('IndexHeroToOccupationalSystem')}
              hoverWhite
            />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
