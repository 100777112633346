import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Feature2 from '../../components/Feature2';
import icon10 from '../../images/icon10.svg';
import icon4 from '../../images/icon44.svg';
import icon5 from '../../images/icon55.svg';
import icon7 from '../../images/icon77.svg';
import icon9 from '../../images/icon9.svg';

export default () => {
  const { t } = useTranslation();

  return (
    <Container className="container-space">
      <div>
        <h2 className="text-blue text-center">{t('IndexWhyToChooseTitle')}</h2>
        <p className="text-center">{t('IndexWhyToChoosePara1')}</p>
      </div>
      <div className="card mt-5">
        <div className="card-body">
          <Row>
            <Feature2
              image={icon9}
              title={t('Feature2Title1')}
              paragraph={t('Feature2Para1')}
              imageAlt={t('Features2Icon1Alt')}
            />
            <Feature2
              image={icon4}
              title={t('Feature2Title2')}
              paragraph={t('Feature2Para2')}
              imageAlt={t('Features2Icon2Alt')}
            />
            <Feature2
              image={icon5}
              title={t('Feature2Title3')}
              paragraph={t('Feature2Para3')}
              imageAlt={t('Features2Icon3Alt')}
            />
            <Feature2
              image={icon10}
              title={t('Feature2Title4')}
              paragraph={t('Feature2Para4')}
              imageAlt={t('Features2Icon4Alt')}
            />
            <Feature2
              image={icon7}
              title={t('Feature2Title5')}
              paragraph={t('Feature2Para5')}
              imageAlt={t('Features2Icon5Alt')}
            />
          </Row>
        </div>
      </div>
      <div className="mt-5">
        <p className="text-center">{t('IndexWhyToChoosePara2')}</p>
      </div>
    </Container>
  );
};
