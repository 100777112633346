import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LinkTo from './LinkTo';
import QMCloudsLaptopLaatudokumenttiImg from './images/QMCloudsLaptopLaatudokumenttiImg';
import urls from './urls';

export default () => {
  const { t } = useTranslation();

  return (
    <Container className="container-space">
      <Row>
        <Col>
          <h2 className="text-blue">{t('SaveMoneyFromConsultantTitle')}</h2>
          <div className="line blue-line" />
        </Col>
      </Row>
      <Row>
        <Col lg={5} className="mb-4">
          <ol>
            <li>{t('SaveMoneyFromConsultantLi1')}</li>
            <li className="mt-2">{t('SaveMoneyFromConsultantLi2')}</li>
            <li className="mt-2">{t('SaveMoneyFromConsultantLi3')}</li>
            <li className="mt-2">{t('SaveMoneyFromConsultantLi4')}</li>
          </ol>
          <LinkTo
            url={`${urls.qualityUrl}#documents`}
            text={t('ReadMoreEditableDocuments')}
          />
          <LinkTo url={urls.softwareUrl} text={t('ReadMoreLinkToSoftware')} />
          <LinkTo
            url={urls.qualityManualUrl}
            text={t('ReadMoreLinkToQualityManual')}
          />
        </Col>
        <Col lg={7}>
          <QMCloudsLaptopLaatudokumenttiImg />
        </Col>
      </Row>
    </Container>
  );
};
