import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactRequest from '../../components/ContactRequest';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsMobileBgtImg from '../../components/images/QMCloudsMobileBgImg';

export default () => {
  const { t } = useTranslation();

  return (
    <BackgroundImage>
      <Container className="container-space text-white">
        <Row>
          <Col lg={6} className="mb-2">
            <QMCloudsMobileBgtImg className="img-w-450" />
          </Col>
          <Col lg={6}>
            <h2 className="pt-4 text-white">{t('HowToUseAppTitle')}</h2>
            <div className="line white-line" />
            <p>{t('HowToUseAppP')}</p>
            <h3 className="text-white">{t('HowToUseAppSubTitle')}</h3>
            <ol className="instructions-list">
              <li>{t('HowToUseAppListItem1')}</li>
              <li>{t('HowToUseAppListItem2')}</li>
              <li>{t('HowToUseAppListItem3')}</li>
            </ol>
            <ContactRequest isBackgroundBlue />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
