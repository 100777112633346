import React from 'react';
import { Col } from 'react-bootstrap';

interface Props {
  title: string;
  paragraph: string;
  image: string;
  imageAlt: string;
}

export default (props: Props) => {
  const { title, paragraph, image, imageAlt } = props;
  return (
    <>
      <Col lg={1}>
        <img src={image} className="img img-w-50" alt={imageAlt} />
      </Col>
      <Col lg={5}>
        <h3 className="mt-3 mt-lg-0 text-blue sub-title-size no-line-height">
          {title}
        </h3>
        <p>{paragraph}</p>
      </Col>
    </>
  );
};
