import React from 'react';
import Slider, { Settings } from 'react-slick';
import AlhonHuoltoLogoImage from './customer-logo-images/AlhoHuoltoLogo';
import AoCrewLogoImage from './customer-logo-images/AoCrewLogo';
import ArkkitehtuuriNiskanenLogoImage from './customer-logo-images/ArkkiitehtuuriNiskanenLogo';
import DealCompLogoImage from './customer-logo-images/DealCompLogo';
import DuallaserLogoImage from './customer-logo-images/DuallaserLogo';
import FinlightLogoImage from './customer-logo-images/FinlightLogo';
import FinnglassLogoImage from './customer-logo-images/FinnglassLogo';
import HellmaninKonepajaLogoImage from './customer-logo-images/HellmaninKonepajaLogo';
import ImplantonaLogoImage from './customer-logo-images/ImplantonaLogo';
import JvVarustelutLogoImage from './customer-logo-images/JvVarustelutLogo';
import KiGroupLogoImage from './customer-logo-images/KiGroupLogo';
import KiinteistoVarustamoLogoImage from './customer-logo-images/KiinteistoVarustamoLogo';
import KokkoselkaLogoImage from './customer-logo-images/KokkoselkaLogo';
import KonecranesLogoImage from './customer-logo-images/KonecranesLogo';
import KonepalveluNumminenLogoImage from './customer-logo-images/KonepalveluNumminenLogo';
import KorjauspartneritLogoImage from './customer-logo-images/KorjauspartneritLogo';
import KuljetusliikeTJRonkkoLogoImage from './customer-logo-images/KuljetusliikeTJRonkkoLogo';
import LoikkaLogoImage from './customer-logo-images/LoikkaLogo';
import LythLogoImage from './customer-logo-images/LythLogo';
import MasematLogoImage from './customer-logo-images/MasematLogo';
import MekaLogoImage from './customer-logo-images/MekaLogo';
import MTechnologyLogoImage from './customer-logo-images/MTechnologyLogo';
import NostoConsultingLogoImage from './customer-logo-images/NostoConsultingLogo';
import PilkePaivakoditLogoImage from './customer-logo-images/PilkePaivakoditLogo';
import PirkaKenkaLogoImage from './customer-logo-images/PirkaKenkaLogo';
import PolarmossLogoImage from './customer-logo-images/PolarmossLogo';
import PorvoonMittaletukuLogoImage from './customer-logo-images/PorvoonMittaletkuLogo';
import ProfessionalConnectionsogoImage from './customer-logo-images/ProfessionalConnectionsLogo';
import RakennusliikeVettenrantaLogoImage from './customer-logo-images/RakennusliikeVettenrantaLogo';
import TaelekLogoImage from './customer-logo-images/TaelekLogo';
import TalokatsastusLogoImage from './customer-logo-images/TalokatsastusLogo';
import TerasputkikalusteLogoImage from './customer-logo-images/TerasputkikalusteLogo';
import TtTeknologiaLogoImage from './customer-logo-images/TtTeknologiaLogo';
import TuudoLogoImage from './customer-logo-images/TuudoLogo';
import UrjalaWorksLogoImage from './customer-logo-images/UrjalaWorksLogo';
import VeviaLogoImage from './customer-logo-images/VeviaLogo';
import VkVattenLogoImage from './customer-logo-images/VkVattenLogo';

const customers = [
  {
    url: 'https://www.pilkepaivakodit.fi',
    image: PilkePaivakoditLogoImage,
  },
  {
    url: 'https://www.finnglass.com/fi',
    image: FinnglassLogoImage,
  },
  {
    url: 'https://www.meka.eu/fi/',
    image: MekaLogoImage,
  },
  {
    url: 'https://www.konecranes.com/fi',
    image: KonecranesLogoImage,
  },
  {
    url: 'https://urjalaworks.com/',
    image: UrjalaWorksLogoImage,
  },
  {
    url: 'https://www.terasputkikaluste.fi/',
    image: TerasputkikalusteLogoImage,
  },
  {
    url: 'https://www.kokkoselka.fi/',
    image: KokkoselkaLogoImage,
  },
  {
    url: 'https://www.finlight.fi/fi/',
    image: FinlightLogoImage,
  },
  {
    url: 'https://www.implantona.fi/',
    image: ImplantonaLogoImage,
  },
  {
    url: 'http://korjauspartnerit.fi/',
    image: KorjauspartneritLogoImage,
  },
  {
    url: 'https://talokatsastus.fi/',
    image: TalokatsastusLogoImage,
  },
  {
    url: 'https://www.mikavettenranta.fi/',
    image: RakennusliikeVettenrantaLogoImage,
  },
  {
    url: 'https://dealcomp.fi/',
    image: DealCompLogoImage,
  },
  {
    url: 'https://www.taelek.fi/',
    image: TaelekLogoImage,
  },
  {
    url: 'https://www.tt-teknologia.fi/fi/etusivu/',
    image: TtTeknologiaLogoImage,
  },
  {
    url: 'http://www.aocrew.fi/',
    image: AoCrewLogoImage,
  },
  {
    url: 'https://www.masemat.fi/',
    image: MasematLogoImage,
  },
  {
    url: 'https://www.konepalvelunumminen.fi/',
    image: KonepalveluNumminenLogoImage,
  },
  {
    url: 'https://helkone.fi/',
    image: HellmaninKonepajaLogoImage,
  },
  {
    url: 'https://www.arkniskanen.fi/',
    image: ArkkitehtuuriNiskanenLogoImage,
  },
  {
    url: 'https://duallaser.fi/',
    image: DuallaserLogoImage,
  },
  {
    url: 'https://www.profco.com/',
    image: ProfessionalConnectionsogoImage,
  },
  {
    url: 'https://www.wisemaster.fi/',
    image: MTechnologyLogoImage,
  },
  {
    url: 'https://www.kiinteistovarustamo.fi/',
    image: KiinteistoVarustamoLogoImage,
  },
  {
    url: 'https://www.nostoconsulting.fi/',
    image: NostoConsultingLogoImage,
  },
  {
    url: 'https://www.pirka-kenka.com/',
    image: PirkaKenkaLogoImage,
  },
  {
    url: 'https://www.loikka.io/fi',
    image: LoikkaLogoImage,
  },
  {
    url: 'https://www.kigroup.fi/',
    image: KiGroupLogoImage,
  },
  {
    url: 'https://www.jvvarustelut.fi/',
    image: JvVarustelutLogoImage,
  },
  {
    url: 'https://polarmoss.fi/',
    image: PolarmossLogoImage,
  },
  {
    url: 'https://vk-vatten.fi/',
    image: VkVattenLogoImage,
  },
  {
    url: 'https://www.kuljetusliiketjronkko.fi/',
    image: KuljetusliikeTJRonkkoLogoImage,
  },
  {
    url: 'https://www.alhonhuolto.fi/',
    image: AlhonHuoltoLogoImage,
  },
  {
    url: 'https://www.tuudo.fi/',
    image: TuudoLogoImage,
  },
  {
    url: 'https://vevia.fi/',
    image: VeviaLogoImage,
  },
  {
    url: 'https://mittaletku.fi/',
    image: PorvoonMittaletukuLogoImage,
  },
  {
    url: 'https://www.lyth.fi/',
    image: LythLogoImage,
  },
];

export default () => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    adaptiveHeight: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="px-4">
      <Slider {...settings}>
        {customers.map((customer, index) => (
          <div key={index}>
            <div className="logo-item">
              <div>
                <a href={customer.url}>
                  <customer.image />
                </a>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
