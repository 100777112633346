import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsDokumenttiMalliImg from '../../components/images/QMCloudsDokumenttiMalliImg';
import LinkTo from '../../components/LinkTo';
import QualityManualRequest from '../../components/QualityManualRequest';
import urls from '../../components/urls';

export default () => {
  const { t } = useTranslation();

  return (
    <BackgroundImage>
      <Container className="container-space text-white">
        <Row>
          <Col lg={6}>
            <h2 className="text-white">{t('DoYouWantCertificateTitle')}</h2>
            <div className="line white-line" />
            <p>{t('DoYouWantCertificateP1')}</p>
            <p>{t('DoYouWantCertificateP2')}</p>
            <LinkTo
              url={urls.qualitySystemUrl}
              text={t('ReadMoreQuality')}
              hoverWhite
            />
            <LinkTo
              url={urls.environmentalSystem}
              text={t('ReadMoreEnvironmental')}
              hoverWhite
            />
            <LinkTo
              url={urls.occupationalSafetySystem}
              text={t('ReadMoreSafety')}
              hoverWhite
            />
            <h3 className="text-white mt-3">
              {t('DownloadDocumentModelTitle')}
            </h3>
            <div className="line white-line" />
            <p>{t('DownloadDocumentModelP')}</p>
            <QualityManualRequest isBackgroundBlue />
          </Col>
          <Col lg={6} className="pt-5">
            <QMCloudsDokumenttiMalliImg className="img-w-450 m-auto" />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
