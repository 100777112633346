import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PilkePaivakoditLogoImage from '../../components/customer-logo-images/PilkePaivakoditLogo';
import RakennusliikeVettenrantaReferenceLogoImage from '../../components/customer-logo-images/RakennusliikeVettenrantaReferenceLogo';
import UrjalaReferenceLogoImage from '../../components/customer-logo-images/UrjalaReferenceLogo';
export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space reference" id="references">
      <Row className="pb-5">
        <Col>
          <h2 className="text-blue text-center">{t('ReferenceTitle')}</h2>
        </Col>
      </Row>
      <Row>
        <Col lg={2} className="m-auto">
          <div className="img-w-200 m-auto">
            <PilkePaivakoditLogoImage />
          </div>
        </Col>
        <Col lg={4}>
          <h3 className="text-blue">{t('ReferencePilke')}</h3>
          <p className="font-weight-bold text-blue">
            {t('ReferencePilkePara1')}{' '}
          </p>
        </Col>
        <Col lg={6} className="my-auto">
          <p>{t('ReferencePilkePara2')}</p>
        </Col>
      </Row>
      <hr />
      <Row className="pt-4">
        <Col lg={2} className="m-auto pb-4">
          <UrjalaReferenceLogoImage />
        </Col>
        <Col lg={4}>
          <h3 className="text-blue">{t('RefrenceUrjalaWorks')}</h3>
          <p className="font-weight-bold text-blue">
            {t('ReferenceUrjalaWorksPara1')}
          </p>
        </Col>
        <Col lg={6} className="my-auto">
          <p>{t('ReferenceUrjalaWorksPara2')}</p>
        </Col>
      </Row>
      <hr />
      <Row className="pt-4">
        <Col lg={2} className="m-auto pb-4">
          <RakennusliikeVettenrantaReferenceLogoImage />
        </Col>
        <Col lg={4}>
          <h3 className="text-blue">{t('RefrenceMikaVettenranta')}</h3>
          <p className="font-weight-bold text-blue">
            {t('ReferenceVettenrantaPara1')}
          </p>
        </Col>
        <Col lg={6} className="my-auto">
          <p>{t('ReferenceVettenrantaPara2')}</p>
        </Col>
      </Row>
    </Container>
  );
};
