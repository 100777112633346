import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SliderLogos from '../../components/SliderLogos';

export default () => {
  const { t } = useTranslation();

  return (
    <Container className="container-space">
      <Row>
        <Col>
          <h2 className="text-blue text-center">{t('CustomersTitle')}</h2>
        </Col>
      </Row>
      <SliderLogos />
    </Container>
  );
};
